@use "../../utils/sassVariables";
//overall container
.navContainer {
  height: 3rem;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 60vw;
  background-image: linear-gradient(
    to right,
    sassVariables.$goldTransparent,
    sassVariables.$gold
  );
  z-index: 1;
  align-items: center;
  justify-content: flex-end;
  //make the transition darker to match the vertical ul
  @media (max-width: 768px) {
    background-image: linear-gradient(
      to right,
      sassVariables.$goldTransparent,
      sassVariables.$goldSemiTransparent 30%,
      sassVariables.$gold
    );
  }
  //list of options
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    transition: height 1s ease;
    margin: 0;
    padding: 0;
    width: 80%;
    @media (max-width: 768px) {
      position: fixed;
      height: calc(100vh - 3rem);
      top: 2.96rem;
      right: 0;
      background-image: linear-gradient(
        to right,
        sassVariables.$goldTransparent,
        sassVariables.$goldSemiTransparent 30%,
        sassVariables.$gold
      );
      flex-direction: column;
      align-items: flex-end;
      width: 60vw;
      justify-content: flex-start;
      overflow: hidden;
    }
    li {
      color: white;
      cursor: pointer;
      font-size: 1.2rem;
      @media (max-width: 768px) {
        padding-right: 3rem;
        margin-top: 3rem;
      }
    }
  }
  .burgerDiv {
    width: 3rem;
    height: 3rem;
    display: none;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-right: 1.5rem;
    @media (max-width: 768px) {
      display: flex;
    }
    span {
      width: 80%;
      border: 2px solid white;
      margin: 0.3rem;
    }
  }
}
