@use "../../utils/sassVariables";
.enquireBanner {
  min-height: 100vh;
  padding-bottom: 3rem;
  flex-direction: column;
  font-family: sans-serif;
  color: sassVariables.$gold;
  h1 {
    margin-top: 5rem;
  }
}
