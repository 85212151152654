.background {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("../../assets/framesBackground.jpeg");
  position: fixed;
  z-index: -1;
}
