.frameContainer {
  display: flex;
  background: none;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100vw;
  justify-content: space-around;
  .frame {
    max-width: 400px;
    min-width: 200px;
    max-height: 600px;
    min-width: 200px;
    height: fit-content;
    width: fit-content;
    background-color: white;
    margin: 3rem 0.5rem;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 480px) {
      width: 80%;
    }
    &:hover {
      transform: rotateY(3deg);
    }
    img {
      height: 100%;
      width: 100%;
      transform: scale(1.01);
    }
  }
}
