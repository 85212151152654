@use "../../utils/sassVariables";

.contactContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    sassVariables.$gold,
    sassVariables.$goldTransparent
  );
  height: 3rem;
  width: 60%;
  position: absolute;
  bottom: 0;
  left: 0;
  :first-child {
    color: white;
  }
  h2 {
    margin-left: 2rem;
    @media (max-width: 768px) {
      margin-left: 1rem;
    }
    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }
}
